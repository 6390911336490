import { SearchProvider } from './src/contexts/searchContext'
import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import theme from './src/Theme/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { getCookieValue } from './src/utils/cookies'

const setCookie = () => {
  if (typeof window !== 'undefined' && window) {
    const cookieName = 'kretz-cookie-consent'
    const cookieValue = getCookieValue(cookieName)
    const gaCampaignPrefix = 'G-'
    const gaCampaignID = process.env.GATSBY_GA_CAMPAIGN_ID
    const gaDisableStr = 'ga-disable-' + gaCampaignPrefix + gaCampaignID
    const gaDomainCookie = process.env.GATSBY_DOMAIN

    if (cookieValue && cookieValue['google-third-party-cookies-allowed'] == 1) {
      // GA is allowed

      // Expire previously set GA disabled cookies
      try {
        if (document.cookie.indexOf(gaDisableStr + '=true') > -1) {
          document.cookie = gaDisableStr + '=false; expires=Thu, 01 Jan 1970 00:00:01 UTC;path=/'
        }
      } catch (error) {
        //console.debug('Failed to perform document.cookie=ga-disable-XXXXXX+=false": ', {error})
      }
    } else {
      // GA is denied

      // Set GA disabled cookie to true
      if (document && document.cookie) {
        try {
          if (document.cookie.indexOf(gaDisableStr + '=false') > -1) {
            document.cookie = gaDisableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/'
          }
        } catch (error) {
          //console.debug('Failed to perform document.cookie=ga-disable-XXXXXX+=true": ', {error})
        }
      }

      // Inform gtag of consent denial
      if (window && window.gtag) {
        try {
          window.gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            security_storage: 'denied',
          })
        } catch (error) {
          //console.debug('Failed to perform gtag: ', {error})
        }
      }

      // Trigger Opt out for GA
      try {
        javascript: gaOptout()
      } catch (error) {
        //console.debug('Unable to perform gaOptout: ', {error})
      }

      // Remove remaining GA cookies if exists
      if (document && document.cookie) {
        try {
          if (document.cookie.indexOf('_ga=') > -1) {
            document.cookie = `_ga=0; expires=Thu, 01 Jan 1970 00:00:01 UTC;domain=${gaDomainCookie};path=/`
          }
          if (document.cookie.indexOf('_ga_' + gaCampaignID + '=') > -1) {
            document.cookie = `_ga_${gaCampaignID}=0;expires=Thu, 01 Jan 1970 00:00:01 UTC;domain=${gaDomainCookie};path=/`
          }
        } catch (error) {
          //console.debug('Failed to remove cookies _ga*": ', {error})
        }
      }

      // Define a GA disabled cookie, set to true, GA is disabled
      window[gaDisableStr] = true
    }
  }
}

export const onClientEntry = () => {
  setCookie()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  setCookie()
}

export const wrapRootElement = ({ element }) => {
  return (
    <SearchProvider>
      <ThemeProvider theme={theme} injectFirst>
        <CssBaseline />
        {element}
      </ThemeProvider>
    </SearchProvider>
  )
}
