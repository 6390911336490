import red from '@material-ui/core/colors/red'
import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
  zIndex: {
    tooltip: 9999,
  },
  palette: {
    primary: {
      main: '#1d1d1b',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    info: {
      main: '#FAE9E5',
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiNativeSelect: {
      select: {
        padding: '1rem !important',
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: '#fff !important',
        },
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          // color: '#000',
        },
      },
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        },
      },
    },
  },
})

export default theme
